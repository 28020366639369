.card{
	max-width: 300px;
	background-color: #146e6f;
	padding: 10px;
	border-radius: 3px;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	position: relative;
	z-index: 0;
	overflow: hidden;
	margin-bottom: 20px;
}
.card::before{
	content: "";
	position: absolute;
	z-index: -1;
	top: -16px;
	left: -16px;
	background: #80b3ff;
	height: 32px;
	width: 32px;
	border-radius: 32px;
	transform: scale(1);
	transform-origin: 50% 50%;
	transition: transform 0.25s ease-out;
}
.card:hover:before{
	transform: scale(15);
}
.top{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.card h1{
	font-size: 20px;
	font-weight: 500;
}
.card:hover h1{
	color: black;
	transition: 1s;
}
.card p{
	font-size: 15px;
	color: lightgray;
}
.card:hover p{
	color: black;
	transition: 1s;
}
.lang{
	display: flex;
}
.lang p{
	position: absolute;
	bottom: 0;
	margin-right: 10px;
	font-size: 10px;
	color: #c6c6c6;;
}
.modal p{
	color: black;
}
.modal h2{
	color: black;
}
.modal_image{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

}
.modal_image img{
	width: 250px;
}


@media only screen and (max-width: 479px) {
	.modal p{
		width: 310px;
		overflow-wrap: break-word;
	}
	.modal_image img{
		margin-bottom: 10px;
	}
}