.other{
	background: #1e1e1e;
	padding: 10vw;
}
.course{
	display: flex;
	flex-wrap: wrap;
	padding-left: 50px;
	margin-bottom: 40px;

}
.course_img img{
	width: 20vw;
	margin-right: 20px;
}
.course_info{
	width: 40vw;
}
.course_info h2{
	margin-top: 0px;
	color: #a3a644;	
}
.course_info p{
	color: #8d8d8d;
}
.course_info button{
	border: 1px solid #195c5e;
	background: transparent;
	padding: 5px;
	color: #8d8d8d;
}
.image1{
	height: 60px;
	border-radius:50%;
}

@media only screen and (max-width: 900px) {
	.image2{
		height: 40px !important;
	}
	.course_img img{
		width: 100%;
	}
	.course_info{
		width: 100%;
		margin-top: 20px;
	}
	.other{
		padding: 20px;
	}
	.course{
		padding-left: 0px;
	}
}