.projects{
	
	background: #1e1e1e;
}
.pro_card{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 20px;
}
@media only screen and (max-width: 479px) {
	.pro_card{
		justify-content: center;
		margin-top: 20px;
	}
}