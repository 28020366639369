.main{
	background: #1e1e1e;
	margin: 10px;
	border-radius: 5px;
}
.header{
	background: #686869;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	height: 20px;
	display: flex;
	align-items: center;
}
.header p{
	color: #939495;
	padding-left: 5px;
	font-size: 15px;

}
.header_two p{
	margin-top: 5px;
	color: #a6a944;
	font-size: 15px;
}
.commands{
	display: flex;
	margin-top: -15px;
}
.commands p{
	font-size: 13px;
}
.body{
	height: 80vh;
}
.footer {
	margin-top: -10px;
}

.circle_icon{
	font-size: 15px !important;
}
.folder_icon{
	font-size: 20px !important;
}
@media only screen and (max-width: 479px) {
	.body{
		height: 75vh;
	}
}