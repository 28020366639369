.contact{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	background: #1e1e1e;
}
.form{
	display: grid;
}
.form p{
	font-size: 30px;
	text-align: center;
	color: #c6c6c6;
}
.form input{
	height: 40px;
	width: 40vw;
	padding: 10px;
	font-size: 20px;
	outline: none;
	color: #c6c6c6;
	background: none;
	border: 1px solid #c6c6c6;
	border-radius: 5px;
	margin-bottom: 15px;

}
.form textarea{
	height: 150px;
	width: 40vw;
	padding: 10px;
	font-size: 20px;
	outline: none;
	color: #c6c6c6;
	background: none;
	border: 1px solid #c6c6c6;
	border-radius: 5px;
	margin-bottom: 15px;
}
.form button{
	height: 35px;
	color: #c6c6c6;
	background: none;
	border: 1px solid #c6c6c6;
	border-radius: 5px;
	font-size: 20px;
}
.form button:hover{
	background-color: #146e6f;
	transition: .5s ease-in;
	border: none;
	color: black;
}
.info{
	margin-top: 50px;
}
.together{
	display: flex;
	align-items: center;
	color: #c6c6c6;
}
.together p{
	margin-left: 15px;
}
.ic{
	border-top: 1px solid #c6c6c6;
	border-bottom: 1px solid #c6c6c6;
	padding-top: 10px;
	padding-bottom: 10px;
}
.row p{
	color: #c6c6c6;
	font-size: 10px;
}
@media only screen and (max-width: 479px) {
	.form input{
		height: 40px;
		width: 300px;
		

	}
	.form textarea{
		height: 150px;
		width: 300px;
		
	}
	.form button{
		height: 35px;
		width: 300px;
		
	}
	.info{
		margin-left: 30px;
	}
	.ic{
		border-top: 1px solid #c6c6c6;
		border-bottom: 1px solid #c6c6c6;
		width: 300px;
	}
}