.skills{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	background: #1e1e1e;

}

.professional{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 10px;

}
.prof_skill > p{
	margin-left: 10px;
	font-size: 25px;
}
.tech_skill > p{
	font-size: 25px;
}

.tech_skill p{
	margin-left: 10px;
}
.inner .progress-bar{
	display: flex;
	justify-content: center;
}
.inner{
	align-items: center;
	padding: 10px;
}
.inner  p{
	text-align: center;
}
.inner > p{
	font-size: 25px;
}
.back{
	max-width: 300px;
}
.tech_div{
	display: flex;
	flex-wrap: wrap;
}
.tech{
	margin-bottom: -10px;
	width: 400px;
}
.tech_left{
	display: flex;
	flex-wrap: wrap;
	margin-left: 10px;
}
.tech p{
	margin-bottom: -10px;
	color: #c6c6c6;
	font-size: 20px;
}
.inner p{
	color: #c6c6c6;
}
.right, .left{
	margin: 20px;
}
.bar{
	margin: 10px;
}
.tech_bar{
	min-width:  !important;
}

@media only screen and (max-width: 479px) {
	.tech{
		width: 350px;
	}
	.professional{
		justify-content: space-around;

	}
}