.footer{
	display: flex;
	align-items: center;
}
.footer p{
	color: #c6c6c6;
	font-size: 13px;
	margin-right: 5px;
}
.footer input{
	height: 15px;
	width: 30vw;
	font-size: 13px !important;
	background: transparent;
	border: none;
	outline: none;
	color: #c6c6c6;
	font-family: 'Menlo',sans-serif;
}
@media only screen and (max-width: 479px) {
	.footer input{
		width: 100px;
	}
}