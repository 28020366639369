.introduction{
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
}
.intro{
	height: 80vh;
	/*background: blue;*/
	width: 50vw;
}
.intro h1{
	padding-top: 10vh;
	font-size: 40px;
}
.intro p{
	width: 40vw;
	color: #c6c6c6;
}
.image{
	height: 70vh;
	/*background: white;*/
	margin-top: 5vw;
	width: 50vw;
	min-width: 10vw;
}

.image img{
	max-height: 65vh;
	min-width: 40px;
	max-width: 45vw;
	padding-left: 2vw;
}
.icons{
	position: absolute;
	bottom: 0;
	left: 40%;
	display: flex;
	text-align: center;
}
.icons a{
	text-decoration: none;
}
.same{
	margin: 10px;
}
.same p{
	padding: 5px;
	margin-top: 0px;
	color: black;
	font-weight: 700;
	border-radius: 2px ;
}

@media only screen and (max-width: 479px) {
  .introduction {
    padding: 5px;
    display: grid;
  }
  .intro{
  	position: absolute;
  	width: 90%;
  	height: 40%;
  }
  
  .intro h1{
  	padding-top: 20px;
  	font-size: 25px;
  }
  .intro p{
  	width: 100%;
  }
  .image img{
  	display: none;
  }
  .icons{
		position: absolute;
		bottom: 50px;
		left: 25%;
		display: flex;
		text-align: center;
	}

	.same{
		margin: 10px;
	}
	.same p{
		padding: 5px;
		margin-top: 0px;
		color: black;
		font-weight: 700;
		border-radius: 2px ;
	}
}